import Clarity from '@microsoft/clarity';


export const useClarity = () => {
  return {
    init: (projectId) => {
      Clarity.init(projectId);
    },
    identify: (userUid, sessionUid, username) => {
      Clarity.identify(userUid, sessionUid, null, username);
    },
    setTag: (key, value) => {
      Clarity.setTag(key, value);
    },
    trackEvent: (eventName) => {
      Clarity.event(eventName);
    }
  };
};