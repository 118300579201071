import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormGroup, FormControl, FormLabel, FormCheck, Form, Button, Alert } from "react-bootstrap/";
import LoaderButton from "../components/LoaderButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


import { useAppContext } from "../libs/contextLib";
import { useHistory, useLocation } from "react-router-dom";
import { useFormFields } from "../libs/hooksLib";
import { useAnalytics } from "../libs/analytics";
import { useClarity } from "../libs/clarity";
// import { useRecaptcha } from "../libs/recaptchLib";

import { onError, Notification } from "../libs/errorLib";
import { API } from "aws-amplify";
import Cookie, { set } from 'js-cookie';

import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";

import "./Login.css";

export default function LoginOrSignup(props) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { trackEvent } = useAnalytics();
  const clarity = useClarity();
  const { isAuthenticated, userHasAuthenticated, getSession, setSession, login, signup } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true)
  const [isSignup, setIsSignup] = useState(false)
  const [fields, handleFieldChange] = useFormFields({
    email: searchParams.get('email') || "",
    confirmEmail: "",
    password: "",
    fullname: "",
    confirmPassword: "",
  });
  const [curSession, setCurSession] = useState(null);
  // const { initRecaptcha, executeRecaptcha } = useRecaptcha();

  useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      console.log(searchParams)
      if (searchParams.get('code')) {
        handleFieldChange({ target: { id:'purchaseCode', name: 'purchaseCode', value: searchParams.get('code') }})
      }    
    

      async function initSession() {
        let session = await getSession()
        if (session) {
          setCurSession(session)
        }
      }
      initSession()

      // initRecaptcha()
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    
    if (location?.state?.isSignup) {
      setIsLogin(location?.state?.isLogin)
      setIsSignup(location?.state?.isSignup)
    }
    
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      
      async function identifySession() {
        let session = await getSession()
        if (session) {
          console.log('identify', session)
          clarity.identify(session.session.user_uid, session.session._id, session.session.username)
        }
      }
      identifySession()

    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   const loadScriptByURL = (id, url, callback) => {
  //     const isScriptExist = document.getElementById(id);
   
  //     if (!isScriptExist) {
  //       var script = document.createElement("script");
  //       script.type = "text/javascript";
  //       script.src = url;
  //       script.id = id;
  //       script.onload = function () {
  //         if (callback) callback();
  //       };
  //       document.body.appendChild(script);
  //     }
   
  //     if (isScriptExist && callback) callback();
  //   }
   
    
  //   // console.log('loading recaptcha')
  //   // load the script by passing the URL
  //   loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
  //     console.log("recaptcha v4 loaded!");
  //   });
  // }, []);

  // function verifyRecaptcha(token) {
  //   let result = API.post("vivelo", "/auth/recaptcha/verify", {
  //     body: { token: token }
  //   });
  //   return result
  // }

  function validateForm() {
    const loginOk = fields.email.length > 0 && fields.password.length > 0      
    const signupOk = fields.fullname.length > 0 && fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.email === fields.confirmEmail &&
      fields.password === fields.confirmPassword;    

    if (isLogin) {
      return loginOk
    }
    if (isSignup) {
      return signupOk
    }    
  }

  async function handleSubmit(event) {    
      event.preventDefault(); 

      trackEvent({
        action: "Clicked Activate Button",
        category: "PurchaseFunnel"
      });
            
      try {
        setIsLoading(true);

        let session = await getSession()
        console.log(isAuthenticated, session)
        if (!isAuthenticated && !session) {
            if (isLogin) {
              session = await login(fields.email, fields.password)
              if (!session) {
                Notification('error', 'Usuario o contraseña inválidos')
              }
            } else {
              // const captchaResult = await executeRecaptcha('signup')
              // if (captchaResult.success) {
              //   console.log('success ', captchaResult)
                // session = await signup(fields.fullname, fields.email, fields.password, ['Viewer'])
                session = await signup(fields.fullname, fields.email, fields.password, ['Viewer'])
                if (session === false) {
                  Notification('error', 'Ya existe un usuario registrado con ese correo')
                }
              // } else {
              //   Notification('error', 'Error de verificación.  Por favor vuelve a intentar.')
              // }
              
              
            }
        }

      } catch (err) {
        onError(err)
        if (err.response) {
          console.error("activation error status", err.response)
          switch(err.response.status) {
            case 401: {
              Notification('error','Usuario o contraseña inválida')
              break;
            }            
            default: {                            
              Notification('error','Error de autenticación')
              break;
            }
          }
        } else {
          console.error("activation exception", err)
          Notification('error','Ocurrió un error')
        }
      } finally {
        setIsLoading(false);
      }


      
  }
  

  function renderSignupFields() {
    return <>
    <h1>Crea tu cuenta</h1>
    <div className="description">Ingresa tus datos y crear tu cuenta para continuar. Si ya tienes una cuenta, <a href="#" onClick={goToLogin} >Inicia sesión</a></div>
    <FormGroup controlId="fullname" size="lg">
      <FormLabel>Nombre</FormLabel>
      <FormControl
        autoFocus
        required
        type="fullname"
        autoComplete="off"
        className={fields.fullname == "" ? "field-error" : "field-ok"}
        value={fields.fullname}
        onChange={handleFieldChange}
      />
    </FormGroup>
    <FormGroup controlId="email" size="lg">
      <FormLabel>Correo</FormLabel>
      <FormControl
        required
        type="email"
        autoComplete="off"
        className={fields.email == "" ? "field-error" : "field-ok"}
        value={fields.email}
        onChange={handleFieldChange}
      />
    </FormGroup>
    <FormGroup controlId="confirmEmail" size="lg">
      {/* <FormLabel>Confirma tu Correo</FormLabel> */}
      <FormControl
        required
        type="email"
        autoComplete="off"
        placeholder="Confirma tu correo"
        className={fields.email != fields.confirmEmail ? "field-error" : "field-ok"}
        value={fields.confirmEmail}
        onChange={handleFieldChange}
      />
    </FormGroup>
    <FormGroup controlId="password" size="lg">
      <FormLabel>Contrase&ntilde;a</FormLabel>
      <FormControl
      required
        type="password"
        className={fields.password == "" ? "field-error" : "field-ok"}
        value={fields.password}
        onChange={handleFieldChange}
      />
    </FormGroup>
    <FormGroup controlId="confirmPassword" size="lg">
      {/* <FormLabel>Confirma tu Contrase&ntilde;a</FormLabel> */}
      <FormControl
        required
        type="password"
        autoComplete="off"
        placeholder="Confirma tu contraseña"
        className={fields.password != fields.confirmPassword ? "field-error" : "field-ok"}
        onChange={handleFieldChange}
        value={fields.confirmPassword}
      />
    </FormGroup>
    <LoaderButton
          block
          type="submit"
          size="lg"
          isLoading={isLoading}
          disabled={!validateForm()}
          variant={validateForm() ? 'primary' : 'secondary'}
        >
          Crear cuenta
        </LoaderButton>
    <div class="login-footer">¿Ya tienes una cuenta? <a href="#" onClick={goToLogin} >Inicia sesión</a></div>
    </>
  }

  function renderLoginFields() {
    return <>
    <h1>Inicia sesión</h1>
    <div className="description">Si ya tienes usuario ingresa a tu cuenta para continuar, <br />o <a href="#" onClick={goToSignup} >crea tu cuenta</a></div>
    <Form.Group controlId="email" size="lg">
    <Form.Label>Correo</Form.Label>
    <Form.Control      
      type="email"
      value={fields.email}
      onChange={handleFieldChange}
    />
  </Form.Group>
  <Form.Group controlId="password" size="lg">
    <Form.Label>Contraseña</Form.Label>
    <Form.Control
      value={fields.password}
      onChange={handleFieldChange}
      type="password"
    />
    <div>
      <a href={`/password/recover`} className="password-recover">Olvid&eacute; mi contrase&ntilde;a</a>
    </div>
  </Form.Group>
  {/* <Form.Group controlId="forceLogout" size="lg">
    <Form.Check 
      type="checkbox"            
      id={`forceLogout`}
      name="forceLogout"
      value={true}
      checked={true}
      // onChange={handleFieldChange}
      label={`Cerrar sesiones abiertas en otros dispositivos`}
      readOnly
    /> 
  </Form.Group> */}
  {/* <Alert variant="info">Si tienes problemas para iniciar sesión con tu usuario por favor intenta&nbsp; 
  <a href={`/password/recover`}  style={{color:'black'}} >recuperar la clave</a> o <a href="#" style={{color:'black'}} onClick={goToSignup} >crear tu cuenta</a> de nuevo</Alert> */}
  <LoaderButton
          block
          type="submit"
          size="lg"
          isLoading={isLoading}
          disabled={!validateForm()}
          variant={validateForm() ? 'primary' : 'secondary'}
        >
          Continuar
        </LoaderButton>
  <div class="login-footer">¿Primera vez en vívelo? <br /><Button block size="md" variant="success" onClick={goToSignup} >Crea tu cuenta</Button></div>
  </>
  }
   
  function goToLogin(e) {
    e.preventDefault()
    setIsLogin(true)
    setIsSignup(false)
  }

  function goToSignup(e) {
    e.preventDefault()
    setIsLogin(false)
    setIsSignup(true)
  }


  function renderForm() {    
    return <><Container>
    <Row style={{ }}>
      <Col md="4">
   
    <form onSubmit={handleSubmit}>
          { isLogin && renderLoginFields() }
          { isSignup && renderSignupFields() }
      </form>

      </Col>
      </Row>
    </Container></>
  }


  return (
    <div className="content content-dark">
      {renderForm()}
    </div>
  );
}